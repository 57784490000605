<template>
<AppLayout>
    <template v-slot:appContent>
        <div class=" d-sm-flex justify-content-between align-items-center pb-1  ">
            <h4 class="">Coupons/edm</h4>
            <div>
                <router-link :to="{...previousRoute}">
                    <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
                </router-link>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <h6 class="text-primary text-bold-600">Coupon Information</h6>
                <div class="row">
                    <div class="col-md-3 mb-1">
                        <span class="text-muted text-italic text-bold-500">Coupon Name : </span>
                        <span>{{coupon?.name ?? 'N/A'}}</span>

                    </div>
                    <div class="col-md-3 mb-1">

                        <span class="text-muted text-italic text-bold-500"> Coupon Code : </span>
                        <span>{{coupon?.code ?? 'N/A'}}</span>

                    </div>
                    <div class="col-md-3 mb-1">
                        <span class="text-muted text-italic text-bold-500"> Coupon Type : </span>
                        <span>{{coupon?.type ?? 'N/A'}}</span>
                    </div>
                    <div class="col-md-3 mb-1">

                        <span class="text-muted text-italic text-bold-500"> Coupon Discount Type : </span>
                        <span>{{coupon?.discount_type ?? 'N/A'}}</span>

                    </div>
                    <div class="col-md-3 mb-1">

                        <span class="text-muted text-italic text-bold-500"> Coupon Discount Amount : </span>
                        <span>{{coupon?.discount ?  (coupon?.discount/100).toFixed(2)  : 'N/A'}}</span>

                    </div>
                    <div class="col-md-3 mb-1">

                        <span class="text-muted text-italic text-bold-500"> Coupon Status : </span>
                        <span>{{coupon?.status ?? 'N/A'}}</span>

                    </div>
                    <div class="col-md-3 mb-1">
                        <span class="text-muted text-italic text-bold-500"> Coupon start at : </span>
                        <span>{{coupon?.start_at ?? 'N/A'}}</span>
                    </div>
                    <div class="col-md-3 mb-1">
                        <span class="text-muted text-italic text-bold-500"> Coupon end at : </span>
                        <span>{{coupon?.end_at ?? 'N/A'}}</span>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-5 py-2">
                        <!-- <h6 class="text-primary text-bold-600 py-2">Select Template </h6> -->
                        <div class="pb-2">
                            <label>Select Template </label>
                            <VueMultiselect :allowEmpty="false" v-model="selectedTemplate" class="" :options="emailTemplates" :close-on-select="true" label="name" track-by="id" :show-labels="false" />
                        </div>
                        <div class="d-flex justify-content-end py-2" v-if="coupon?.type !== 'Global'">
                  
                  <button class="btn btn-primary ml-2" @click="sendCouponNotificationHandler()">Send Coupon Notification</button>

              </div>
                        <div v-if="coupon?.type === 'Global'">
                            <div class="py-1">
                                <h6>Choose customer types</h6>
                                <div class="custom-control custom-checkbox mb-2" style="margin-top:5px;">
                                    <input type="checkbox" @change="selectAllCustomerTypeHandler()" v-model="selectAllCustomerType" class="custom-control-input" id="selectAllCustomerType" true-value=true false-value=false>
                                    <label for="selectAllCustomerType" class="custom-control-label">
                                        Select All
                                    </label>
                                </div>
                                <div class="custom-control custom-checkbox" @click="selectAllCustomerType=false">
                                    <input type="checkbox" v-model="selectedCustomerTypes" class="custom-control-input" id="home" value="1">
                                    <label for="home" class="custom-control-label">
                                        Home
                                    </label>
                                </div>
                                <div class="custom-control custom-checkbox" style="margin-top:5px;" @click="selectAllCustomerType=false">
                                    <input type="checkbox" v-model="selectedCustomerTypes" class="custom-control-input" id="businessType" value="2">
                                    <label for="businessType" class="custom-control-label">
                                        Business
                                    </label>
                                </div>
                                <div class="custom-control custom-checkbox" style="margin-top:5px;" @click="selectAllCustomerType=false">
                                    <input type="checkbox" v-model="selectedCustomerTypes" class="custom-control-input" id="guestType" value="0">
                                    <label for="guestType" class="custom-control-label">
                                        Guests
                                    </label>
                                </div>
                            </div>
                            <div class="py-1">
                                <h6>Choose States</h6>
                                <div class="custom-control custom-checkbox mb-2" style="margin-top:5px;">
                                    <input type="checkbox" @change="selectAllStateHandler()" v-model="selectAllState" class="custom-control-input" id="selectAllState" true-value=true false-value=false>
                                    <label for="selectAllState" class="custom-control-label">
                                        Select All
                                    </label>
                                </div>
                                <div class="custom-control custom-checkbox" @click="selectAllState= false" v-for="(state,key) in stateList?.value ?? []" :key="key" style="margin-top:5px;">
                                    <input type="checkbox" v-model="selectedStates" name="states" class="custom-control-input" :id="state.value" :value="state.value">
                                    <label :for="state.value" class="custom-control-label">
                                        {{ state.name }}</label>
                                </div>

                            </div>

                            <div>
                                <label>Post Codes </label>
                                <input v-model="postCodes" type="text" class="form-control" placeholder="Ex : 2532,3012,4012">
                            </div>

                        </div>

                    </div>
                    <div class="col-md-7">
                        <h5 class="text-primary text-bold-600 py-2 text-center">Preview</h5>
                        <div style="max-height: 500px; overflow-y: scroll;" v-html="previewTemplate ">
                        </div>
                    </div>

                </div>
                <div class="d-flex justify-content-end py-2" v-if="coupon?.type === 'Global'">
                  
                    <button class="btn btn-primary ml-2" @click="sendCouponNotificationHandler()">Send Coupon Notification</button>

                </div>
            </div>
        </div>

    </template>
</AppLayout>
</template>

<script>
// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

// mixins
import Authorization from "@/components/backEnd/mixins/Authorization";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
// core packages
import {
    mapActions,
    mapGetters
} from "vuex";

// 3rd party packages
// import {
//     DatePicker
// } from "v-calendar";

export default {
    name: "CouponEdm",
    components: {
        AppLayout,

        // DatePicker
    },
    mixins: [ShowToastMessage, Loader, Authorization],
    data() {
        return {
            selectedTemplate: {},
            sendToOptions: [{
                    id: 0,
                    name: 'Assign Only'
                },
                {
                    id: 1,
                    name: 'Global'
                },
            ],
            sendTo: "",
            postCodes: '',
            customerTypeOptions: [{
                    name: 'Business',
                    value: 2
                },
                {
                    name: 'Home',
                    value: 1
                }

            ],
            selectedCustomerTypes: [],
            selectedStates: [],
            selectAllState: false,
            selectAllCustomerType: false,
            previewTemplate: ""

        }

    },
    watch: {
        selectedTemplate: function (selectedTemplate) {
            if (selectedTemplate ?.name) {
                this.getPreviewTemplate(selectedTemplate.id);
            }
        }
    },
    computed: {
        ...mapGetters({
            previousRoute: 'previousRoute',
            coupon: 'appCoupons/coupon',
            emailTemplates: "appEmailTemplates/emailTemplates",
            stateList: 'appSettings/settingDefaultState',
            // paginateLinks: 'appCouponUsages/paginateLinks',
            // couponUsages: 'appCouponUsages/couponUsages',
        }),

    },
    methods: {
        ...mapActions({
            getSettings: 'appSettings/getSettings',
            getCoupon: 'appCoupons/getCoupon',
            getEmailTemplates: "appEmailTemplates/getEmailTemplates",

            sendMarketingNotify: "appCoupons/sendMarketingNotify",
            couponPreview: "appCoupons/couponPreview",
            // getCouponUsage: 'appCouponUsages/getCouponUsage',
        }),

        async getPreviewTemplate(templateId) {
            this.loader(true);
            const response = await this.couponPreview({
                id: this.coupon.id,
                data: {
                    marketing_for: "email",
                    template_id: templateId
                }
            });
            console.log(response, "async");
            this.loader(false);
            if (response ?.status === 200) {
                this.previewTemplate = response.data.data;
            }
            if (response ?.message) {
                this.showToastMessage(response);
            }
        },

        async sendCouponNotificationHandler() {
            
            const data = {
                marketing_for: "email",
                template_id: this.selectedTemplate.id,
            }
            if (this.coupon.type === "Global") {
                
                if (this.selectedCustomerTypes.length > 0) {
                   data.type = this.selectedCustomerTypes.map(singleType=> parseInt(singleType));
                }

                if (this.postCodes.length === 0) {
                    this.showToastMessage({type:'error',message:'Please enter post codes'}); 
                    return;
                }
                else {
                    data.post_code = this.postCodes.split(",").map(singlePostCode=> parseInt(singlePostCode));
                }


                if (this.selectedStates.length > 0) {
                    data.state = this.selectedStates;
                }

               
            }
          

            const dataObj = {
                id: this.coupon.id,
                data
            };

            this.loader(true);
            const response = await this.sendMarketingNotify(dataObj);
            this.loader(false);

            if (response?.message) {
                this.showToastMessage(response);
            }
            if (response?.status === 204) {
                this.showToastMessage(response);
                this.$router.push({
                    name: 'list-coupons'
                });
            }
            

        },

        selectAllStateHandler() {
            if (this.selectAllState === "true") {
                this.selectedStates = this.stateList ?.value ?.map(state => state.value) ?? [];

            } else {
                this.selectedStates = [];
               
            }
        },
        selectAllCustomerTypeHandler() {
            if (this.selectAllCustomerType === "true") {
                this.selectedCustomerTypes = [0,1,2]

            } else {
                this.selectedCustomerTypes = [];
               
            }
        },

        async getEmailTemplateList() {
            this.loader(true);
            const response = await this.getEmailTemplates({
                order_by_name: 'ASC',
                type: 9
            });
            this.loader(false);
            if (response ?.status === 200) {
                this.selectedTemplate = this.emailTemplates ?. [0] ?? {};
            }
            if (response ?.message) {
                this.showToastMessage(response);
            }
        },

        async getCouponById(id) {
            this.loader(true);
            const response = await this.getCoupon({
                id
            });
            console.log(response);
            this.loader(false);
            if (response ?.status === 404) {
                this.$router.push({
                    name: 'appCouponList'
                });
                this.showToastMessage({
                    message: "Not a valid coupon",
                    type: 'error'
                });
            }
            if (response ?.message) {
                this.showToastMessage(response);
            }
        },
        async getSettingsList(settingParams) {
            const response = await this.getSettings(settingParams);
            if (response ?.message) {
                this.showToastMessage(response);
            }
        },

    },
    async mounted() {
        // get coupon infromation
        await this.getCouponById(this.$route.params.id);
        await this.getSettingsList({
            type: ['default'],
            key: ['default_state'],
        });
        await this.getEmailTemplateList();

    },

}
</script>

<style scoped>

  </style>
